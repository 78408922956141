<template>
  <section class="ratio_asos">
    <div class="container">
      <div class="row m-0">
        <div class="col-sm-12 p-0">
          <div class="title title-2">
            <div class="row align-items-center text-center text-md-start flex-column">
              <div class="col justify-content-center text-center">
                  <h2>{{ highlightName }}</h2>
                  <h5 v-if="highlightDescription">{{ highlightDescription }}</h5>
              </div>
              <div class="col d-flex justify-content-end mt-2 mt-md-0 pe-4">
                <NuxtLink class="theme-color" :to="{ path: '/search', query: { tag: highlightData?.tag.humanUrlFormat } }">Lihat Semua <i class="fas fa-chevron-right"></i></NuxtLink>
              </div>
            </div>
          </div>
          <div class="product-wrapper slide-5_1 d-flex">
            <template v-if="!highlightLoading">
              <div class="swiper-button-prev" :class="`prev-highlight-${highlightKey}`"></div>
              <swiper v-bind="swiperOption" 
                      :navigation="{ nextEl: `.next-highlight-${highlightKey}`, prevEl: `.prev-highlight-${highlightKey}` }"
                      class="swiper-wrapper px-1">
                <swiper-slide class="swiper-slide" v-for="(product, index) in highlightProducts.get(highlightData?.tag.humanUrlFormat)" :key="index">
                  <productBox :product="product" />
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-item-view-all">
                    <nuxt-link :to="{ path: '/search', query: { tag: highlightData?.tag.humanUrlFormat } }" class="btn btn-solid-default">Lihat Semua</nuxt-link>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-next" :class="`next-highlight-${highlightKey}`"></div>
            </template>
            <div class="row" v-if="highlightLoading">
              <div class="col-3 d-none d-sm-block" v-for="i in 4" :key="`skeleton-recommended-product-${i}`">
                <Skeletor height="200px" />
              </div>
              <div class="col-4 d-sm-none" v-for="i in 3" :key="`skeleton-recommended-product-${i}`">
                <Skeletor height="100px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts" setup>
import productBox from "~/layout/elements/productBox/index.vue";
import "swiper/css/pagination";
import { Pagination, Navigation } from 'swiper';
import { ProductItem } from '~/contract/vo/ProductRelated.vo';
import { HighlightResponse } from '~/contract/dto/HighlightRelated.dto';
import { PropType } from "nuxt/dist/app/compat/capi";
import { useHighlightStore } from "~/store/highlight";
import { storeToRefs } from "pinia";
import { useLayout } from "~/store/layout";

const props = defineProps({
  highlightKey: { type: String },
  highlightData: { type: Object as PropType<HighlightResponse> },
  highlightName: { type: String },
  highlightDescription: { type: String },
})
const currenthighlightData = toRef(props, 'highlightData')

const highlightStore = useHighlightStore();
const layoutStore = useLayout();

const { highlightProducts, highlightLoading } = storeToRefs(highlightStore)

onMounted(async () => nextTick(async () => {
  await highlightStore.retrieveProduct(currenthighlightData.value?.tag.humanUrlFormat)
}))

const swiperOption = ref({
  modules: [Pagination, Navigation],
  loop: false,
  freeMode: false,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 6,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 5,
      spaceBetween: 24
    },
    575: {
      slidesPerView: 4,
      spaceBetween: 12
    },
    0: {
      spaceBetween: 12,
      slidesPerView: 3,
    }
  }
})

// Computed property to determine the color based on layoutMode
const buttonColor = computed(() => layoutStore.layoutMode === 'dark' ? '#f7f7f7' : '#252525');

// Watch for changes in buttonColor and update CSS variable
watch(buttonColor, (newColor) => {
  document.documentElement.style.setProperty('--swiper-color', newColor);
});
</script>

<style lang="scss" scoped>

.swiper-button-next, .swiper-button-prev {
    color: var(--theme-color);
    background-blend-mode: color-dodge;
    position: inherit;
    height: inherit;
    margin-top: inherit;
    z-index: inherit;
    
    &::after{
      font-size: 1.5rem !important;
    }
}

.swiper-item-view-all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(135deg, rgba(189,189,189,0.1) 0px, rgba(189,189,189,0.1) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
  border-radius: 10px;
}

html {
  &.dark {
    .swiper-item-view-all {
      background-image: radial-gradient(circle at center center, transparent,rgb(33,33,33)),repeating-linear-gradient(135deg, rgb(33,33,33) 0px, rgb(33,33,33) 2px,transparent 2px, transparent 10px,rgb(33,33,33) 10px, rgb(33,33,33) 11px,transparent 11px, transparent 21px),repeating-linear-gradient(45deg, rgb(47,47,47) 0px, rgb(47,47,47) 4px,transparent 4px, transparent 8px),linear-gradient(90deg, rgb(33,33,33),rgb(33,33,33));
    }
  }
}

.container{
  @media (max-width: 575.98px) { 
    padding: 0;
  }
}
</style>