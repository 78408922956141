import { toast } from "vue3-toastify";
import { RegisterNewsletterDto } from "~/contract/dto/NewsletterRelated.dto";
import ApiService, { BaseResponse } from "~/service/ApiService";

export const useNewsletterStore = defineStore("newsletter", {
  state: () => ({
    loading: false,
  }),
  actions: {
    async signUpNewsletter(payload: RegisterNewsletterDto) {
      this.loading = true;
      const { data, pending, error, refresh }: any = await ApiService.post(
        "/api/v1/newsletter",
        payload,
        true
      );
      this.loading = pending.value;
      if (error.value) {
        this.loading = false;
        throw error.value;
      }
      if (data.value) {
        const response = data.value as BaseResponse;
        toast(response.message, { type: "success" })
      }
    },
  },
});
