<template>
  <section class="home-section home-section-1" style="position: sticky">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="mega-slider">
          <Skeletor height="41.6vh" v-if="sliderLoading" class="d-none d-sm-block"/>
          <Skeletor height="150px" v-if="sliderLoading" class="d-sm-none"/>
          <swiper v-bind="swiperOption" v-if="!sliderLoading">
            <swiper-slide v-for="(slide, index) in megaSliderList" :key="index">
              <sliderItem :slide="slide" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="grid-banner">
          <div class="row" v-if="sliderLoading">
            <div class="col-lg-6 col-3 banner-container" v-for="i in 4" :key="`skeleton-mini-banner-${i}`">
              <Skeletor height="20vh" class="d-none d-sm-block"/>
              <Skeletor height="75px" class="d-sm-none"/>
            </div>
          </div>
          <div class="row" v-if="!sliderLoading">
            <div
              class="col-lg-6 col-3 banner-container"
              v-for="(slide, index) in smallSliderList"
              :key="index"
            >
              <miniSliderItem :slide="slide" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import sliderItem from "./sliderItem.vue";
import miniSliderItem from "./miniSliderItem.vue";
import { Navigation, EffectCoverflow, Autoplay, Pagination } from "swiper";
import "swiper/css/effect-coverflow";
import { useLayout } from "~~/store/layout";
import { SliderItemVo } from "~/contract/vo/SliderRelated.vo";
import { BannerItemVo } from "~/contract/vo/BannerRelated.vo";
import { UrlHrefType } from "~/contract/enum/ResourceRelated.enum";

import { useSliderStore } from "~/store/slider";

const props = defineProps({
  megaSliderList: { type: Array<SliderItemVo>, required: true },
  smallSliderList: { type: Array<BannerItemVo>, required: true },
});

const swiperOption = ref({
  modules: [Navigation, EffectCoverflow, Autoplay, Pagination],
  loop: true,
  freeMode: false,
  pagination: {
    clickable: true,
  },
  navigation: false,
  effect: "coverflow",
  autoplay: {
    delay: 3000,
  },
});

const layoutDirection = computed(() => useLayout().layoutType);

const sliderLoading = computed(() => useSliderStore().loading);
</script>

<style>
@-webkit-keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSD {
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
}

.swiper-slide-active > .fadeInUp {
  animation-name: fadeInUpSD;
  animation-duration: 1s;
  opacity: 1;
  width: 100%;
  padding: 10px 20px 30px 0;
}

.swiper-slide.swiper-slide-active .fadeInUp {
  animation-name: fadeInUpSD;
  animation-duration: 1s;
  opacity: 1;
  width: 100%;
}
</style>
