<template>
  <section class="container category-menu-section">
    <div class="row">
      <div class="product-wrapper" v-if="featuredLoading">
        <div class="row">
          <div class="col-3" v-for="i in 4" :key="`skeleton-category-${i}`">
            <Skeletor height="10vh"/>
          </div>
        </div>
      </div>
      <div v-else class="product-wrapper">
        <swiper v-bind="swiperOption" class="swiper-wrapper">
          <swiper-slide
            class="swiper-slide"
            v-for="(category, index) in featuredCategories"
            :key="`featured-category-${index}`"
          >
            <buttonItem
              :category="category"
              v-if="category.icon.type == IconFeaturedCategoryType.IMAGE"
            />
            <buttonItemIcon
              :category="category"
              v-if="category.icon.type == IconFeaturedCategoryType.ICON"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import buttonItem from "./buttonItem.vue";
import buttonItemIcon from "./buttonItemIcon.vue";
import { useCategoryMenuCustomStore } from "~~/store/categoryMenuCustom";
import { Pagination } from "swiper";
import { IconFeaturedCategoryType } from "~/contract/enum/ResourceRelated.enum";
import { Skeletor } from "vue-skeletor";
import { FeaturedCategoryVo } from "~/contract/vo/CategoryRelated.vo";
import { PropType } from "nuxt/dist/app/compat/capi";

let featuredCategories = computed(() => {
  return useCategoryMenuCustomStore().featured;
});
onMounted(() => nextTick(async () => {
  await useCategoryMenuCustomStore().fetchFeaturedCategory();
}))
const featuredLoading = computed(() => useCategoryMenuCustomStore().loading);
let swiperOption = {
  modules: [Pagination],
  loop: false,
  freeMode: true,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 8,
      spaceBetween: 24,
    },
    991: {
      slidesPerView: 6,
      spaceBetween: 24,
    },
    575: {
      slidesPerView: 5,
      spaceBetween: 12,
    },
    0: {
      spaceBetween: 13,
      slidesPerView: 4,
    },
  },
};
</script>
