<template>

  <Head>
    <Title>Situs Jual Beli Vape Terlengkap, Mudah, & Aman</Title>
    <Link rel="icon" href="/favicon.ico">
    </Link>
  </Head>
  <MainLayout :offerCode="offerCode">
    <HomeSlider :megaSliderList="megaSlider" :smallSliderList="smallSlider" />
    <categoryMenu />
    <!-- <customerServices /> -->
    <!-- <LatestProducts
      :latestProduct="latestProduct"
      :latestProductLoading="latestProductLoading"
    /> -->
    <FlashSale v-if="flashsaleData && flashsaleData.tag" :flashsale-data="flashsaleData" :flashsale-name="flashsaleData.tag.name"
      :flashsale-description="flashsaleData.tag.description || ''" />
      
    <Highlight v-for="(highlight, i) in highlightData" :key="`highlight-item-${i}-${highlight.order}`"
      :highlightKey="`highlight-item-${i}-${highlight.order}`"
      :highlightData="highlight"
      :highlightName="highlight.tag.name"
      :highlightDescription="highlight.tag.description" />
    <ShopByBrand />
    <subscribe />
    <!-- <newsLetterModal /> -->
    <tosModal />
  </MainLayout>
</template>

<script lang="ts" setup>
import MainLayout from "~/layout/layouts/index.vue";
import HomeSlider from "~/components/home/slider/index.vue";
import categoryMenu from "~/components/home/categoryMenu/index.vue";
import Highlight from "~/components/home/highlight/index.vue";
import FlashSale from "~/components/home/flashSale/index.vue";
import ShopByBrand from "~/components/home/shopByBrand/index.vue";
import { useLayout } from "~~/store/layout";
import subscribe from "~/components/home/subscribe/index.vue";
import { useSliderStore } from "~/store/slider";
import { useProductCustomStore } from "~/store/productCustom";
import tosModal from "~/layout/elements/modals/tosModal.vue"
import { useHighlightStore } from "~/store/highlight";
import { useFlashsaleStore } from "~/store/flashsale";
import { storeToRefs } from "pinia";
useHead({
  title: "Situs Jual Beli Vape Terlengkap, Mudah, & Aman",
  link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
});
const offerCode = "NEWVISITOR10";
let themeCss = "/css/demo1.css";

useLayout().setPrimaryColor({ primaryColor: "#f32e4a" });
let layoutMode = useCookie("layoutType").value || "light";

if (layoutMode === "dark") themeCss = "/css/demo1_dark.css";
else themeCss = "/css/demo1.css";

const highlightStore = useHighlightStore();
const flashsaleStore = useFlashsaleStore();

onMounted(
  async () =>
    await nextTick(async () => {
      // !useCookie("newsLetterSet").value &&
      //   useClickStore().toggleNewsLetterModal();
      await useSliderStore().fetchMegaSliderList();
      await useSliderStore().fetchSmallSliderList();
      await useProductCustomStore().retrieveLatestProduct();
      await highlightStore.fetchHighlightData();
      await flashsaleStore.fetchFlashsaleData();
    })
);

const megaSlider = computed(() => useSliderStore().megaSlider);
const smallSlider = computed(() => useSliderStore().smallSlider);

// const latestProduct = computed(() => useProductCustomStore().latestProduct);
// const latestProductLoading = computed(
//   () => useProductCustomStore().latestProductLoading
// );

const {highlightData, highlightLoading} = storeToRefs(highlightStore)

const {flashsaleData, flashsaleLoading} = storeToRefs(flashsaleStore)

</script>

<style lang="scss">
@import "@/assets/scss/demo1.scss";
</style>
