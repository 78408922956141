<template>
  <div>
    <div
      class="modal fade"
      id="ageConfirmationModal"
      tabindex="-1"
      aria-labelledby="ageConfirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title fw-bold mx-auto">PERINGATAN</h5>
          </div>
          <div class="modal-body">
            <p class="text-center m-0">
              Situs ini mengandung produk rokok elektrik yang ditujukan untuk
              pengguna dewasa 18 tahun ke atas, harap meninggalkan situs jika
              Anda di bawah usia tersebut
            </p>
          </div>
          <div class="modal-footer border-0">
            <div class="row mx-auto">
              <div class="col p-2">
                <button
                  type="button"
                  class="btn btn-success rounded border-0 w-100"
                  id="myInput"
                  data-bs-dismiss="modal"
                  @click="confirmAge"
                >
                  SAYA BERUSIA 18 TAHUN ATAU LEBIH
                </button>
              </div>
              <div class="col p-2">
                <button
                  type="button"
                  class="btn btn-danger rounded border-0 w-100"
                  data-bs-dismiss="modal"
                  @click="redirectUnderAge"
                >
                  SAYA BELUM BERUSIA 18 TAHUN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const isConfirmedAge = useCookie("isConfirmedAge");
const router = useRouter();

const closeModal = () => {
  const confirmationModal = document.getElementById("ageConfirmationModal");
  if (confirmationModal) {
    confirmationModal.classList.remove("show");
    confirmationModal.removeAttribute("aria-modal");
    confirmationModal.style.display = "none";
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      document.body.removeChild(modalBackdrop);
    }
  }
};
const confirmAge = () => {
  isConfirmedAge.value = (new Date()).getTime().toString();
  closeModal();
};
const redirectUnderAge = () => {
  window.location.href = "https://www.google.com";
};

onMounted(() => {
  nextTick(() => {
    if (!isConfirmedAge.value) {
      const confirmationModal = document.getElementById("ageConfirmationModal");
      if (confirmationModal) {
        confirmationModal.classList.add("show");
        confirmationModal.setAttribute("aria-modal", "true");
        confirmationModal.style.display = "block";
        const modalBackdrop = document.createElement("div");
        modalBackdrop.classList.add("modal-backdrop", "fade", "show");
        document.body.appendChild(modalBackdrop);
      }
    }
  });
});
</script>

<style lang="scss" scoped>
/* Your scoped styles go here */
</style>
