import { SliderItemResponseDto } from "~/contract/dto/SliderRelated.dto";
import { SliderType } from "~/contract/enum/SliderRelated.enum";
import { BannerItemVo } from "~/contract/vo/BannerRelated.vo";
import { SliderItemVo } from "~/contract/vo/SliderRelated.vo";
import ApiService from "~/service/ApiService";

export const useSliderStore = defineStore('slider', {
    state: () => ({
        megaSlider: [] as Array<SliderItemResponseDto>,
        smallSlider: [] as Array<SliderItemResponseDto>,
        loading: false,
    }),
    actions: {
        async fetchMegaSliderList() {
            if(this.megaSlider.length > 0) return this.megaSlider
            this.loading = true
            const { data, pending, error, refresh }: any = await ApiService.query(
                '/api/v1/slider', 
                {
                    type: SliderType.MEGA
                }, 
                true)
            this.loading = pending.value;
            if(error.value){
                this.loading = false
                throw error.value
            }
            if (data.value && data.value.data) {
                const response = data.value.data as Array<SliderItemResponseDto>
                this.megaSlider = response as Array<SliderItemVo>;
            }
        },
        async fetchSmallSliderList() {
            if(this.smallSlider.length > 0) return this.smallSlider
            this.loading = true
            const { data, pending, error, refresh }: any = await ApiService.query(
                '/api/v1/slider', 
                {
                    type: SliderType.SMALL
                }, 
                true)
            this.loading = pending.value;
            if(error.value){
                this.loading = false
                throw error.value
            }
            if (data.value && data.value.data) {
                const response = data.value.data as Array<SliderItemResponseDto>
                this.smallSlider = response as Array<BannerItemVo>;
            }
        },
    },
});