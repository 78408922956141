import { ProductBrandItem } from "~/contract/vo/ProductBrandRelated.vo";
import ApiService from "~/service/ApiService";

export const useBrandsStore = defineStore("brands", {
  state: () => ({
    productBrands: [] as Array<ProductBrandItem>,
    productBrandsLoading: false,
  }),
  actions: {
    async fetchProductBrands() {
      if(this.productBrands.length > 0) return this.productBrands
      this.productBrandsLoading = true;
      const { data, pending, error, refresh }: any = await ApiService.query(
        "/api/v1/product-brand",
        {},
        true
      );
      this.productBrandsLoading = pending.value;
      if (error.value) {
        this.productBrandsLoading = true;
        throw error.value;
      }
      if (data.value && data.value.data) {
        const response = data.value.data as Array<ProductBrandItem>;
        this.productBrands = response as Array<ProductBrandItem>;
      }
    },
  },
});
