<template>
  <section class="ratio_asos">
    <div class="container p-sm-0">
      <div class="row m-0">
        <div class="col-sm-12 p-0">
          <div class="title title-2">
            <div class="row align-items-center flex-column">
              <div class="col d-flex justify-content-center align-items-center">
                <span class="heading">
                  <h2>{{ flashsaleName }}</h2>
                  <h5 v-if="flashsaleDescription">{{ flashsaleDescription }}</h5>
                </span>
              </div>
              <div class="col d-flex justify-content-between align-items-center">
                <span class="counter justify-content-around flex-md-row">
                  <div>
                    <vue3-flip-countdown :deadline="endTime" countdownSize="1rem" labelSize=".8rem" :flipAnimation="false" />
                  </div>
                </span>
                <NuxtLink class="theme-color" :to="{ path: '/search', query: { tag: flashsaleData?.tag.humanUrlFormat } }">Lihat Semua <i class="fas fa-chevron-right"></i></NuxtLink>
              </div>
            </div>
          </div>
          <div class="product-wrapper slide-5_1 d-flex">
            <template v-if="!currentFlashsaleLoading">
              <div class="swiper-button-prev prev-flashsale"></div>
              <swiper v-bind="swiperOption"
                      :navigation="{ nextEl: '.next-flashsale', prevEl: '.prev-flashsale' }"
                      class="swiper-wrapper">
                <swiper-slide class="swiper-slide" v-for="(product, index) in currentflashsaleProduct" :key="index">
                  <productBox :product="product" />
                </swiper-slide>
                <swiper-slide>
                  <div class="swiper-item-view-all">
                    <nuxt-link :to="{ path: '/search', query: { tag: flashsaleData?.tag.humanUrlFormat } }" class="btn btn-solid-default">Lihat Semua</nuxt-link>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-next next-flashsale"></div>
            </template>
            <div class="row" v-if="currentFlashsaleLoading">
              <div class="col-3 d-none d-sm-block" v-for="i in 4" :key="`skeleton-recommended-product-${i}`">
                <Skeletor height="200px" />
              </div>
              <div class="col-4 d-sm-none" v-for="i in 3" :key="`skeleton-recommended-product-${i}`">
                <Skeletor height="100px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts" setup>
import productBox from "~/layout/elements/productBox/index.vue";
import "swiper/css/pagination";
import { Navigation, Pagination } from 'swiper';
import { ProductItem } from '~/contract/vo/ProductRelated.vo';
import { HighlightResponse } from '~/contract/dto/HighlightRelated.dto';
import { PropType } from "nuxt/dist/app/compat/capi";
import { useHighlightStore } from "~/store/highlight";
import { useFlashsaleStore } from "~/store/flashsale";
import { FlashsaleResponse } from "~/contract/dto/FlashsaleRelated.dto";
import { useLayout } from "~/store/layout";


const props = defineProps({
  flashsaleLoading: { type: Boolean },
  flashsaleData: { type: Object as PropType<FlashsaleResponse> },
  flashsaleName: { type: String },
  flashsaleDescription: { type: String },
})
const currentFlashsaleData = toRef(props, 'flashsaleData')
const currentFlashsaleLoading = toRef(props, 'flashsaleLoading')
const flashsaleStore = useFlashsaleStore();
const layoutStore = useLayout();

const currentflashsaleProduct = computed(() => flashsaleStore.flashsaleProducts as Array<ProductItem>)
const startTime = computed(() => flashsaleStore.startTime as Date)

const endTime = computed(() => {
  const endTimeDate = new Date(flashsaleStore.endTime);
  return endTimeDate.toString();
});
const size = "10px"

onMounted(() => nextTick(() => {
  flashsaleStore.retrieveProduct(currentFlashsaleData.value?.tag.humanUrlFormat)
}))

const swiperOption = ref({
  modules: [Pagination, Navigation],
  loop: false,
  freeMode: false,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 6,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 5,
      spaceBetween: 24
    },
    575: {
      slidesPerView: 4,
      spaceBetween: 12
    },
    0: {
      spaceBetween: 12,
      slidesPerView: 3,
    }
  }
})

// Computed property to determine the color based on layoutMode
const buttonColor = computed(() => layoutStore.layoutMode === 'dark' ? '#f7f7f7' : '#252525');

// Watch for changes in buttonColor and update CSS variable
watch(buttonColor, (newColor) => {
  document.documentElement.style.setProperty('--swiper-color', newColor);
});
</script>

<style lang="scss" scoped>
:deep .no-animation__card {
  font-size: 1rem !important;
}

.swiper-button-next, .swiper-button-prev {
    color: var(--swiper-color);
    background-blend-mode: color-dodge;
    position: inherit;
    height: inherit;
    margin-top: inherit;
    z-index: inherit;
}

.swiper-item-view-all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 288px;
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
}
</style>