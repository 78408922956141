<template>
  <section class="subscribe-section section-b-space">
    <div>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-6">
            <div class="subscribe-details">
              <h2 class="mb-3">{{ useRuntimeConfig().public.const.SubscribeOurNews }}</h2>
              <h6>
                {{ useRuntimeConfig().public.const.SubscribeDescription }}
              </h6>
            </div>
          </div>

          <form class="col-lg-4 col-md-6 mt-md-0 mt-3" @submit.prevent="handleRegisterNewsletter">
            <div class="subscribe-input">
              <div class="input-group">
                <input type="email" class="form-control subscribe-input" placeholder="Masukkan Email Anda" v-model="formData.email" required :disabled="loading"/>
                <button class="btn btn-solid-default" type="submit" :disabled="loading">
                  {{ useRuntimeConfig().public.const.Submit }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useNewsletterStore } from "~/store/newsletter";
const newsletterStore = useNewsletterStore()

const { loading } = storeToRefs(newsletterStore)
const formData = ref({
  email: ""
})

const handleRegisterNewsletter = async (values: any) => {
  newsletterStore.signUpNewsletter({
    email: formData.value.email
  }).then(() => {
    formData.value.email = ""
  })
} 
</script>
  