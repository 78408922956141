<template>
  <section class="ratio_asos">
    <div class="container p-sm-0 mb-4">
      <div class="row m-0">
        <div class="col-sm-12 p-0">
          <div class="title title-2 text-center">
            <h2>Shop By Brand</h2>
            <h5>Tersedia berbagai pilihan brand</h5>
          </div>
          <div class="row" v-if="productBrandsLoading">
            <div
              class="col-lg-2 col-md-3 col-4 my-3"
              v-for="i in 15"
              :key="`brand-skeleton-${i}`"
            >
              <Skeletor height="100px"/>
            </div>
          </div>
          <div class="row" v-if="!productBrandsLoading">
            <div
              class="col-lg-2 col-md-3 col-4 brand-item"
              v-for="brand in productsBrands"
              :key="`brand-${brand.order}`"
            >
              <nuxt-link
                :href="{
                  path: 'search',
                  query: { tag: brand.tag?.humanUrlFormat },
                }"
              >
                <img :src="brand.icon?.value" alt="brand" />
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Skeletor } from "vue-skeletor";
import { useBrandsStore } from "~/store/brands";
onMounted(() =>
  nextTick(() => {
    useBrandsStore().fetchProductBrands();
  })
);
const productBrandsLoading = computed(() => {
  return useBrandsStore().productBrandsLoading;
});
const productsBrands = computed(() => {
  return useBrandsStore().productBrands;
});
</script>

<style lang="scss" scoped>
.brand-item {
  border-width: 1px !important;
  border-color: lightgray !important;
  border-style: solid;
  text-align: center;
  padding: 40px;
  display: flex;

  @media only screen and (max-width: 600px) {
    padding: 25px;
  }

  a{
    align-content: center;
    align-self: center;

    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
